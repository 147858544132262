<template>
  <VueSimpleSuggest
    :filter-by-query="false"
    ref="suggestComponent"
    :list="getList"
    mode="input"
    v-model="valueChild"
    :debounce="300"
    :styles="styles"
  >
    <div slot="suggestion-item" slot-scope="scope">
      <span v-html="boldenSuggestion(scope)"></span>
    </div>
  </VueSimpleSuggest>
</template>

<script>
import { GeneralService } from "../GeneralService";

export default {
  props: {
    value: {}
  },
  data: () => ({
    styles: {
      defaultInput: "form-control"
    }
  }),
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    reset() {
      this.$refs.suggestComponent.setText("");
    },
    setText(val) {
      this.$refs.suggestComponent.setText(val);
    },
    getList(search) {
      return new Promise(rsv => {
        GeneralService.getCategories({
          search
        }).then(res => {
          rsv(res.data.map(x => x.name));
        });
      });
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    }
  }
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("REQUERIMIENTO "+_vm._s(_vm.requirement_id))]),_c('entity-head-info',{staticClass:"ml-1 mb-2 d-none d-print-flex",attrs:{"entity_id":1}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 d-print-none"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.status_id != 3),expression:"status_id != 3"}],staticClass:"card card-body"},[_c('FormCode',{ref:"formCode",attrs:{"requirement_id":_vm.requirement_id},on:{"submitted":function($event){return _vm.list.data.unshift($event)}}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Observaciones")]),_c('app-comments',{attrs:{"url":_vm.$route.path}})],1),_c('app-button-submit',{directives:[{name:"show",rawName:"v-show",value:(_vm.status_id == 1),expression:"status_id == 1"}],attrs:{"text":"Solicitar"},on:{"click":function($event){_vm.status_id = 2;
          _vm.save();}}}),_c('button',{staticClass:"btn btn-light",on:{"click":function($event){_vm.per_page = _vm.total;
          _vm.$refs.tr.gl();}}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" Ver Completo ")]),_c('button',{staticClass:"btn btn-light",on:{"click":function($event){return _vm.letsPrint()}}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Imprimir ")]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.$refs.elShowPreDel.show()}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Eliminar marcados ")]),_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.getXlsx()}}},[_c('i',{staticClass:"fa fa-file-excel-o"}),_vm._v(" Excel ")]),_c('app-button-load',{directives:[{name:"show",rawName:"v-show",value:(_vm.status_id == 2),expression:"status_id == 2"}],staticClass:"btn-light",attrs:{"text":"Entregar"},on:{"click":function($event){return _vm.proveer()}}}),_c('categories',{staticClass:"mt-2",attrs:{"requirementId":_vm.requirement_id}}),_c('router-link',{attrs:{"to":("/logistic/requirements/" + _vm.requirement_id + "/quotation")}},[_vm._v("Cotizacion")])],1),_c('div',{staticClass:"col"},[_c('app-table-registers',{ref:"tr",attrs:{"getList":_vm.getList,"bordered":true}},[_c('template',{slot:"top"},[_c('div',{staticClass:"mb-3 form-inline d-print-none"},[_c('app-button-submit',{on:{"click":function($event){_vm.$refs.mForm.show();
                _vm.$refs.dForm.reset();}}},[_vm._v(" Nuevo ")]),_c('app-button-load',{staticClass:"btn-light",attrs:{"text":"Cargar productos Requeridos"},on:{"click":function($event){return _vm.$refs.elQuestPrevLoadProd.show()}}}),_c('app-input-search',{staticClass:"ml-auto",on:{"search":function($event){_vm.search = $event;
                _vm.$refs.tr.gl();}}})],1)]),_c('template',{slot:"table"},[_c('thead',[_c('tr',[_c('th'),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.status_id == 2),expression:"status_id == 2"}]},[_c('i',{staticClass:"fa fa-link"})]),_c('th',[_vm._v("PRODUCTO")]),_c('th',[_vm._v("MARCA")]),_c('th',[_vm._v("CATEGORIA")]),_c('th',[_vm._v("CANTIDAD")]),_c('th',{staticClass:"d-print-none"})])]),_c('tbody',_vm._l((_vm.list.data),function(l){return _c('tr',{key:l.id},[_c('td',[_c('app-checkbox',{model:{value:(l.to_delete),callback:function ($$v) {_vm.$set(l, "to_delete", $$v)},expression:"l.to_delete"}},[_c('i',{staticClass:"fa fa-trash"})])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.status_id == 2),expression:"status_id == 2"}]},[_c('app-checkbox',{model:{value:(l.to_get),callback:function ($$v) {_vm.$set(l, "to_get", $$v)},expression:"l.to_get"}},[_c('i',{staticClass:"fa fa-envelope"})])],1),_c('td',[_c('div',[_vm._v(_vm._s(l.product_name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(l.brand_name),expression:"l.brand_name"}],staticClass:"badge badge-light"})]),_c('td',[_vm._v(_vm._s(l.brand_name))]),_c('td',[_vm._v(_vm._s(l.categorie_name))]),_c('td',{staticClass:"text-center"},[_c('app-span-input',{attrs:{"sm":true,"value":l.quantity},on:{"input":function($event){l.quantity = $event;
                    _vm.saveDetail(l);}}},[_vm._v(_vm._s(l.quantity)+" ")])],1),_c('td',{staticClass:"d-print-none"},[_c('button',{staticClass:"btn btn-light btn-sm",on:{"click":function($event){_vm.$refs.dDel.show();
                    _vm.$refs.dDel.val = l.id;}}},[_c('i',{staticClass:"fa fa-trash"})])])])}),0)])],2)],1)]),_c('app-modal-yn',{ref:"elQuestPrevLoadProd",on:{"yes":function($event){return _vm.cargarProductos()}}},[_c('p',[_vm._v("Cargar productos requeridos? se perdaran algunos cambios")])]),_c('app-modal-basic',{ref:"mForm"},[_c('FormDetail',{ref:"dForm",attrs:{"requirement_id":_vm.requirement_id},on:{"submitted":function($event){return _vm.addDetail($event)}}})],1),_c('app-modal-yn',{ref:"elShowPreDel",on:{"yes":function($event){return _vm.deleteAllToDelete()}}},[_c('p',[_vm._v("Eliminar a todas las filas marcadas?")])]),_c('app-modal-yn',{ref:"dDel",on:{"yes":function($event){_vm.$refs.dDel.hide();
      _vm.deleteItem(_vm.$refs.dDel.val);}}},[_c('p',[_vm._v("Eliminar el Registro?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
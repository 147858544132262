<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct v-model="product_id" ref="sel"></SelectProduct>
    </div>
    <div class="form-group">
      <label for>Cantidad</label>
      <input type="number" class="form-control" v-model="quantity" />
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import { LogisticService } from "../service";
import SelectProduct from "../../store-module/products/Select";

export default {
  components : {
    SelectProduct
  },
  data: () => ({
    quantity: 1,
    product_id: undefined
  }),
  props: {
    requirement_id: {}
  },
  methods: {
    reset() {
      this.quantity = 1;
      this.product_id = undefined;
      this.$refs.sel.reset();
    },
    load() {},
    save() {
      let oBody = {
        requirement_id: this.requirement_id,
        product_name: this.$refs.sel.val.name,
        ...this.$data
      };

      LogisticService.saveRequirementDetail(oBody).then(res => {
        this.$emit("submitted", {id: res, ...oBody});
      });
    }
  }
};
</script>

<style>
</style>

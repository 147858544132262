<template>
  <div class="media">
    <!-- <img src="..." class="mr-3" alt="..."> -->
    <!-- <app-img-viewer -->
    <!--   class="mr-3" -->
    <!--   style="width:180px" -->
    <!--   :fileId="reg.file_id" -->
    <!-- ></app-img-viewer> -->
    <img style="width:180px" :src="regImageFile" class="mr-3" alt="">
    <div class="media-body">
      <h3 class="mt-0">{{ reg.name }}</h3>
      <div>Direccion: {{ reg.address }}</div>
      <slot></slot>
    </div>
  </div>

  <!-- <div class="row">
    
    <div class="col">
      <h4>{{ reg.name }}</h4>
      <p>Direccion: {{ reg.address }}</p>
      <slot></slot>
    </div>
  </div> -->
</template>
<script>
import { AdminService } from "../AdminService";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    entity_id: {}
  },

  data: () => ({
    reg: {}
  }),

  computed: {
    regImageFile() {
      return process.env.VUE_APP_API_URL + "files/" + this.reg.file_id;
    }
  },

  watch: {
    //
  },

  created() {
    AdminService.getEntity(this.entity_id).then((res) => {
      this.reg = res;
    });
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
